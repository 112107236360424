import I18n from 'locales/index.js'

export const process_totals_stages = {
  time_to_capability_questionnaire_complete: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_complete.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_complete.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_capability_questionnaire_reviewed: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_capability_questionnaire_reviewed.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'ncq_referred'
  },
  time_to_self_diagnostic_complete: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_complete.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_complete.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_self_diagnostic_reviewed: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_self_diagnostic_reviewed.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'online_assessment_referred'
  },
  time_to_on_sites_booked: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_booked.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_booked.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'pending_on_site_assessment'
  },
  time_to_on_sites_attended: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_attended.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_sites_attended.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_first_on_site_draft_submitted: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_first_on_site_draft_submitted.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_first_on_site_draft_submitted.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'on_site_assessment_report_required'
  },
  time_to_on_site_drafts_accepted: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_drafts_accepted.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_drafts_accepted.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_on_site_attended_to_accepted: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_attended_to_accepted.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_on_site_attended_to_accepted.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_first_action_plan_submission: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_first_action_plan_submission.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_first_action_plan_submission.help_text'),
    to_scale: true,
    kpi_able: false
  },
  time_to_action_plan_feedback: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_action_plan_feedback.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_action_plan_feedback.help_text'),
    to_scale: true,
    kpi_able: true,
    kpi_key: 'action_plan_submitted'
  },
  time_to_recommended_for_granting_after_initial_action_plan: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_recommended_for_granting_after_initial_action_plan.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_recommended_for_granting_after_initial_action_plan.help_text'),
    to_scale: false,
    kpi_able: false
  },
  time_to_f4n_granting_after_recommendation: {
    name: I18n.t('staff.reports.workload_report.process_totals.time_to_f4n_granting_after_recommendation.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.time_to_f4n_granting_after_recommendation.help_text'),
    to_scale: true,
    kpi_able: false
  },
  total_time_for_f4n_granting: {
    name: I18n.t('staff.reports.workload_report.process_totals.total_time_for_f4n_granting.name'),
    help_text: I18n.t('staff.reports.workload_report.process_totals.total_time_for_f4n_granting.help_text'),
    to_scale: false,
    kpi_able: false
  }
}

export const current_workload_stage_names = {
  pending_capability_questionnaires: {
    name: I18n.t('staff.reports.workload_report.current_workload.pending_capability_questionnaires.name'),
    help_text: false
  },
  pending_self_diagnostics: {
    name: I18n.t('staff.reports.workload_report.current_workload.pending_self_diagnostics.name'),
    help_text: false
  },
  on_sites_to_book: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_sites_to_book.name'),
    help_text: false
  },
  upcoming_on_site_visits: {
    name: I18n.t('staff.reports.workload_report.current_workload.upcoming_on_site_visits.name'),
    help_text: false
  },
  on_site_drafts_to_submit: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_to_submit.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_to_submit.help_text')
  },
  on_site_drafts_rejected: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_rejected.name'),
    help_text: false
  },
  on_site_drafts_pending_acceptance: {
    name: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_pending_acceptance.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.on_site_drafts_pending_acceptance.help_text')
  },
  initial_action_plan_required: {
    name: I18n.t('staff.reports.workload_report.current_workload.initial_action_plan_required.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.initial_action_plan_required.help_text')
  },
  action_plans_requiring_feedback: {
    name: I18n.t('staff.reports.workload_report.current_workload.action_plans_requiring_feedback.name'),
    help_text: false
  },
  pre_granting: {
    name: I18n.t('staff.reports.workload_report.current_workload.pre_granting.name'),
    help_text: false
  },
  upcoming_arranged_visits: {
    name: I18n.t('staff.reports.workload_report.current_workload.upcoming_arranged_visits.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.upcoming_arranged_visits.help_text')
  },
  total_companies_at_cq: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_companies_at_cq.name'),
    help_text: false
  },
  total_companies_in_assessment: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_companies_in_assessment.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_companies_in_assessment.help_text')
  },
  total_granted_companies: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_granted_companies.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_granted_companies.help_text')
  },
  total_companies_advised: {
    name: I18n.t('staff.reports.workload_report.current_workload.total_companies_advised.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.total_companies_advised.help_text')
  },
  advised_hibernating_companies: {
    name: I18n.t('staff.reports.workload_report.current_workload.advised_hibernating_companies.name'),
    help_text: I18n.t('staff.reports.workload_report.current_workload.advised_hibernating_companies.help_text')
  },
  require_maintain_and_sustain: {
    name: I18n.t('staff.reports.workload_report.current_workload.require_maintain_and_sustain.name'),
    help_text: false
  },
  booked_maintain_and_sustain: {
    name: I18n.t('staff.reports.workload_report.current_workload.booked_maintain_and_sustain.name'),
    help_text: false
  }
}

export const completed_workload_stage_names = {
  capability_questionnaires_reviewed: {
    name: I18n.t('staff.reports.workload_report.completed_workload.capability_questionnaires_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.capability_questionnaires_reviewed.help_text'),
    workload_key: 'pending_capability_questionnaires',
    workload_name: current_workload_stage_names.pending_capability_questionnaires.name,
    color: '#e6194B',
    workload_color: '#e6194B66'
  },
  self_diagnostics_reviewed: {
    name: I18n.t('staff.reports.workload_report.completed_workload.self_diagnostics_reviewed.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.self_diagnostics_reviewed.help_text'),
    workload_key: 'pending_self_diagnostics',
    workload_name: current_workload_stage_names.pending_self_diagnostics.name,
    color: '#f58231',
    workload_color: '#f5823166'
  },
  on_sites_booked: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_sites_booked.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_sites_booked.help_text'),
    workload_key: 'pending_on_sites_to_book',
    workload_name: current_workload_stage_names.on_sites_to_book.name,
    color: '#ffe119',
    workload_color: '#ffe11966'
  },
  on_sites_attended: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_sites_attended.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_sites_attended.help_text'),
    workload_key: false,
    color: '#bfef45',
    workload_color: '#bfef4566'
  },
  on_site_drafts_first_submitted: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_first_submitted.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_first_submitted.help_text'),
    workload_key: 'pending_on_site_drafts_to_submit',
    workload_name: current_workload_stage_names.on_site_drafts_to_submit.name,
    color: '#3cb44b',
    workload_color: '#3cb44b66'
  },
  on_site_drafts_accepted: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_accepted.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_accepted.help_text'),
    workload_key: 'pending_on_site_acceptances',
    workload_name: 'On-site drafts pending acceptance',
    color: '#42d4f4',
    workload_color: '#42d4f466'
  },
  on_site_drafts_resubmitted: {
    name: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_resubmitted.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.on_site_drafts_resubmitted.help_text'),
    workload_key: false,
    color: '#4363d8',
    workload_color: '#4363d866'
  },
  action_plan_feedback_given: {
    name: I18n.t('staff.reports.workload_report.completed_workload.action_plan_feedback_given.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.action_plan_feedback_given.help_text'),
    workload_key: false,
    color: '#911eb4',
    workload_color: '#911eb466'
  },
  submitted_for_f4n_granting: {
    name: I18n.t('staff.reports.workload_report.completed_workload.submitted_for_f4n_granting.name'),
    help_text: false,
    workload_key: false,
    color: '#f032e6',
    workload_color: '#f032e666'
  },
  granted_decisions: {
    name: I18n.t('staff.reports.workload_report.completed_workload.granted_decisions.name'),
    help_text: false,
    workload_key: false,
    color: '#637854',
    workload_color: '#63785466'
  },
  pre_granting_visits: {
    name: I18n.t('staff.reports.workload_report.completed_workload.pre_granting_visits.name'),
    help_text: false,
    workload_key: false,
    color: '#d46337',
    workload_color: '#d4633766'
  },
  maintain_and_sustain_visits: {
    name: I18n.t('staff.reports.workload_report.completed_workload.maintain_and_sustain_visits.name'),
    help_text: false,
    workload_key: false,
    color: '#aaffc3',
    workload_color: '#aaffc366'
  },
  overall_visits: {
    name: I18n.t('staff.reports.workload_report.completed_workload.overall_visits.name'),
    help_text: false,
    workload_key: false,
    color: '#fabebe',
    workload_color: '#fabebe66'
  },
  hibernating_companies: {
    name: I18n.t('staff.reports.workload_report.completed_workload.hibernating_companies.name'),
    help_text: I18n.t('staff.reports.workload_report.completed_workload.hibernating_companies.help_text'),
    workload_key: false,
    color: '#4b4c6b',
    workload_color: '#4b4c6b66'
  },
}

export const stalled_companies_stage_names = {
  not_completed_ncq: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_completed_ncq.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_completed_ncq.help_text')
  },
  not_completed_online_assessment: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_completed_online_assessment.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_completed_online_assessment.help_text')
  },
  not_booked_on_site_assessment_within_1_months: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_booked_on_site_assessment_within_1_months.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_booked_on_site_assessment_within_1_months.help_text')
  },
  not_accepted_draft_on_site_assessment: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_accepted_draft_on_site_assessment.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_accepted_draft_on_site_assessment.help_text')
  },
  not_submitted_action_plan: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_submitted_action_plan.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_submitted_action_plan.help_text')
  },
  not_had_maintain_and_sustain_visit: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_had_maintain_and_sustain_visit.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_had_maintain_and_sustain_visit.help_text')
  },
  not_started_reassessment: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.not_started_reassessment.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.not_started_reassessment.help_text')
  },
  with_certificate_expiring_within_3_months: {
    name: I18n.t('staff.reports.workload_report.stalled_companies.with_certificate_expiring_within_3_months.name'),
    help_text: I18n.t('staff.reports.workload_report.stalled_companies.with_certificate_expiring_within_3_months.help_text')
  },
}